import {
  ANALYTICS_CATEGORY_HOME,
  ANALYTICS_CATEGORY_FLIGHTS,
  ANALYTICS_CATEGORY_HOTELS,
  ANALYTICS_CATEGORY_PACKAGES
} from '@/constants/analytics'
import { EnumLiteralsOf } from '@/types'
import { DRIVE } from '@/components/Cars/constants'
import { FLY } from '@/components/Flights/constants'
import { STAY } from '@/components/Hotels/constants'

export type PackageProductType = typeof FLY | typeof STAY | typeof DRIVE

export const GA4Product = {
  FLY: 'air',
  STAY: 'hotel',
  DRIVE: 'car',
  PKG: 'bundle',
  NONPATH: 'nonpath'
} as const

export const GA4Event = {
  CLICK_PROMOTION: 'select_promotion',
  VIEW_PROMOTION: 'view_promotion',
  SEARCH: 'search',
  INTERNAL_ELEMENT: 'internal_element',
  SELECT_CONTENT: 'select_content',
  DISPLAY: 'display',
  INTERNAL_LINK: 'internal_link'
}
export type GA4ProductType = EnumLiteralsOf<typeof GA4Product>
export type GA4PageNameType = 'homepage' | 'landing'
export type AnalyticsPageCatagories =
  | typeof ANALYTICS_CATEGORY_HOME
  | typeof ANALYTICS_CATEGORY_FLIGHTS
  | typeof ANALYTICS_CATEGORY_HOTELS
  | typeof ANALYTICS_CATEGORY_PACKAGES

export const GA4PageName: Record<AnalyticsPageCatagories, GA4PageNameType> = {
  PCLN_HOME: 'homepage',
  FLIGHT_HOME: 'landing',
  HOTEL_HOME: 'landing',
  PACKAGES_HOME: 'landing'
}

export const GA4ProductMap = {
  1: GA4Product.FLY,
  5: GA4Product.STAY,
  8: GA4Product.DRIVE
} as const

export const GA4ProductMapShort = {
  1: 'a',
  5: 'h',
  8: 'c'
} as const

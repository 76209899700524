/* eslint-disable class-methods-use-this */
import PCLNAnalytics from '@pcln/analytics'
import { ANALYTICS_CATEGORY_PACKAGES } from '@/constants/analytics'
import { GA4Event } from './ga4/types'

type GA4EventFiringParams = {
  event: string
  attributes: Record<string, unknown>
}

type LogData = {
  message: string
  appName?: string
  appVersion?: string
  cguid?: string
  error?: unknown
  requestUrl?: string
  requestBody?: unknown
  requestMethod?: string
  responseUrl?: string
  status?: number
  statusText?: string
  type?: string
} & Record<string, string | boolean | number | unknown>

function initializePCLNAnalytics() {
  PCLNAnalytics.appName = 'next-landing'
  PCLNAnalytics.appVersion = 'unknown'
}

function fireGA4EventFromBrowser(ga4Props: GA4EventFiringParams) {
  if (
    window?.dataLayer &&
    (ga4Props.event === GA4Event.CLICK_PROMOTION ||
      ga4Props.event === GA4Event.VIEW_PROMOTION)
  ) {
    window.dataLayer.push({ ecommerce: null })
  }
}
class Analytics {
  fireEvent({
    category = ANALYTICS_CATEGORY_PACKAGES,
    action = '',
    data = '',
    event = '',
    label = ''
  }) {
    initializePCLNAnalytics()
    PCLNAnalytics.logEvent({
      action,
      category,
      data,
      event,
      label
    })
  }

  fireGA4Event(ga4Props: GA4EventFiringParams) {
    try {
      initializePCLNAnalytics()
      PCLNAnalytics.GA4Enabled = true
      fireGA4EventFromBrowser(ga4Props)
      PCLNAnalytics.logEvent(ga4Props)
    } finally {
      PCLNAnalytics.GA4Enabled = false
    }
  }

  // Log an error
  // sample query 'index=cheetah sourcetype=ESBROWSEREVENTDATA_ESSVCS action=Error'
  logError(logData: LogData) {
    initializePCLNAnalytics()
    PCLNAnalytics.logError({
      ...logData
    })
  }

  // Log directly to Splunk
  // sample query 'index=cheetah sourcetype=ESBROWSEREVENTDATA_ESSVCS action=Log'
  log({ message = '', ...rest }) {
    initializePCLNAnalytics()
    PCLNAnalytics.log({
      message,
      ...rest
    })
  }

  // Log a warning
  // sample query 'index=cheetah sourcetype=ESBROWSEREVENTDATA_ESSVCS action=warning'
  logWarning({ name = '', message = '' }) {
    initializePCLNAnalytics()
    PCLNAnalytics.logWarning({
      name,
      message
    })
  }
}

export default new Analytics()

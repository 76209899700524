export const ANALYTICS_ACTION_FLIGHTS_SEARCH = 'Flight Search Button'
export const ANALYTICS_ACTION_HOTELS_SEARCH = 'Hotel Search Button'
export const ANALYTICS_CATEGORY_FLIGHTS = 'FLIGHT_HOME'
export const ANALYTICS_CATEGORY_HOME = 'PCLN_HOME'
export const ANALYTICS_CATEGORY_HOTELS = 'HOTEL_HOME'
export const ANALYTICS_CATEGORY_PACKAGES = 'PACKAGES_HOME'
export const ANALYTICS_CATEGORY_VIP_LANDING = 'vip_landing'
export const ANALYTICS_CUSTOM_EVENT = 'gtm-custom-event'
export const INVALID = 'Invalid'
export const VALID = 'Valid'
type AnalyticsCategory =
  | typeof ANALYTICS_CATEGORY_HOME
  | typeof ANALYTICS_CATEGORY_FLIGHTS
  | typeof ANALYTICS_CATEGORY_HOTELS
  | typeof ANALYTICS_CATEGORY_PACKAGES
export const ANALYTICS_CATEGORIES: Record<string, AnalyticsCategory> = {
  '/': ANALYTICS_CATEGORY_HOME,
  '/flights': ANALYTICS_CATEGORY_FLIGHTS,
  '/hotels': ANALYTICS_CATEGORY_HOTELS,
  '/vacationpackages': ANALYTICS_CATEGORY_PACKAGES
}
